<template>
    <div class="box">
        <div class="d-flex flex-wrap" style="gap: 1.5rem">
            <div style="flex: 1 1 400px">
                <h2>{{ $t('global.keyword') }}</h2>
                <form
                    class="d-flex"
                    style="gap: 0.5rem"
                    @submit.prevent="
                        () => {
                            setFilters({ keyword: searchKeyword })
                            $router.push({ name: 'SearchByKeywords', query: { keyword: searchKeyword}})
                        }
                    "
                >
                    <b-input
                        v-model="searchKeyword"
                        class="flex-grow-1"
                        icon="search"
                        name="keyword_search"
                        :placeholder="$t('searchform.typekeyword')"
                    />
                    <b-button type="is-warning" icon-left="search" native-type="submit" />
                </form>
                <p>{{ $t('searchform.alreadypositionned') }}</p>
            </div>

            <div style="flex: 1 1 400px">
                <h2>{{ $t('global.domain') }}</h2>
                <form
                    class="d-flex"
                    style="gap: 0.5rem"
                    @submit.prevent="
                        () => {
                            setFilters({ urlSearch: searchDomain })
                            $router.push({ name: 'SearchByDomain' })
                        }
                    "
                >
                    <b-input
                        v-model="searchDomain"
                        name="site"
                        class="flex-grow-1"
                        icon="search"
                        :placeholder="$t('searchform.enterdomain')"
                    />
                    <b-button type="is-warning" icon-left="search" native-type="submit" />
                </form>
                <p>{{ $t('searchform.adresssite') }}</p>
            </div>

            <div style="flex: 1 1 400px">
                <h2>{{ $t('global.page') }}</h2>
                <form
                    class="d-flex"
                    style="gap: 0.5rem"
                    @submit.prevent="
                        () => {
                            setFilters({ urlSearch: searchPage })
                            $router.push({ name: 'SearchByUrl' })
                        }
                    "
                >
                    <b-input
                        v-model="searchPage"
                        class="flex-grow-1"
                        icon="search"
                        name="site"
                        :placeholder="$t('searchform.enterurl')"
                    />
                    <b-button type="is-warning" icon-left="search" native-type="submit" />
                </form>
                <p>{{ $t('searchform.adresssiteurl') }}</p>
            </div>
        </div>

        <hr />
        <section id="home__cover" class="with__cta bloc-accompagnement-hp" data-speed="0">
            <div class="cover__content cover__content__home">
                <div class="row align-content-center justify-content-center">
                    <div class="col-8">
                        <div class="row row-header">
                            <div class="col-md-4" style="padding-right: 5px; padding-left: 5px">
                                <div class="item--header" data-aos="fade-right" data-aos-delay="500">
                                    <div class="item--header--top" style="min-height: 50px; padding-top: 15px">
                                        {{ $t('searchform.mentorship') }}
                                        <strong>{{ $t('searchform.seo') }}</strong>
                                    </div>
                                    <div class="item--header--content" style="padding: 0.5rem">
                                        <div class="item--header--icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 408.87 408.88"
                                            >
                                                <path
                                                    d="M306.39,154.09c19.63,4.54,35.24,21.26,39.79,39.52a18.18,18.18,0,0,0,36.07-3.18c0-13.08-11-33-26-47.88-14.72-14.54-32.25-24.8-46.69-24.8a18.46,18.46,0,0,0-18.17,18.44A18.23,18.23,0,0,0,306.39,154.09ZM56.63,392.31a36.07,36.07,0,1,0,51,51.06L212,339.07A159.11,159.11,0,1,0,160.94,288ZM182.37,204.06A113.57,113.57,0,1,1,295.94,317.63,113.61,113.61,0,0,1,182.37,204.06Z"
                                                    transform="translate(-46.06 -45.07)"
                                                    style="fill: #f9ae15; fill-rule: evenodd"
                                                />
                                            </svg>
                                            <strong>{{ $t('searchform.visibility') }}</strong>
                                        </div>
                                        <ul>
                                            <li>{{ $t('searchform.opportunity') }}</li>
                                            <li>{{ $t('searchform.analyse') }}</li>
                                            <li>{{ $t('searchform.popularity') }}</li>
                                        </ul>
                                        <div class="item--cta">
                                            <a href="/campaigns" target="blank"
                                                ><strong>{{ $t('searchform.clickhere') }}</strong
                                                ><br />
                                                {{ $t('searchform.help') }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="padding-right: 5px; padding-left: 5px">
                                <div class="item--header" data-aos="fade-left" data-aos-delay="750">
                                    <div class="item--header--top" style="min-height: 50px; padding-top: 15px">
                                        {{ $t('searchform.companionship') }}
                                        <strong>{{ $t('searchform.offsiteseo') }}</strong>
                                    </div>
                                    <div class="item--header--content" style="padding: 0.5rem">
                                        <div class="item--header--icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="30"
                                                viewBox="0 0 200 126.7"
                                            >
                                                <path
                                                    d="M167.35,105,147.73,65.69a4.08,4.08,0,0,0-.89-1.18,3.88,3.88,0,0,0-1.23-.75l-23-8.27a14.6,14.6,0,0,0-10.2.1L94.94,62.25a35.73,35.73,0,0,0-3.65,1.63l-1.19.65A33.61,33.61,0,0,0,86,62.65a31.6,31.6,0,0,0-13.81-1.9l-11.75,1a3.41,3.41,0,0,0-1.5.49,3.75,3.75,0,0,0-1.2,1.11L31.26,101a4.23,4.23,0,0,0-.74,2.8,4.13,4.13,0,0,0,1.19,2.61l11.51,11.4a9.33,9.33,0,0,0,7.6,13.78l.4,0a9.32,9.32,0,0,0,8.29,12.11,9.34,9.34,0,0,0,8.65,12.88,9.3,9.3,0,0,0,6.61-2.74,9.36,9.36,0,0,0,8.63,7.91l.62,0a9.49,9.49,0,0,0,3.24-.59A9.56,9.56,0,0,0,93,162.83a9.77,9.77,0,0,0,6.63-3l-6.33-6.31c0-.15,0-.29,0-.44a9.31,9.31,0,0,0-.46-3.55l13,12.91a12.59,12.59,0,0,0,5.27.93,13,13,0,0,0,9.34-4.61l1.3-1.5a2.36,2.36,0,0,0,.29-.48l-19.94-19.88A1.42,1.42,0,0,1,102,135a1.19,1.19,0,0,1,1.76,0l23.71,23.65a11.11,11.11,0,0,0,4.12.61,11.45,11.45,0,0,0,8.22-4.06l3.13-3.62a4.69,4.69,0,0,0,1.1-3.19l-20.8-20.74a1.41,1.41,0,0,1-.1-1.89,1.19,1.19,0,0,1,1.76,0l20.9,20.84a9.23,9.23,0,0,0,2.69.31,9.4,9.4,0,0,0,6.73-3.32l.84-1a10.83,10.83,0,0,0,2.57-7.46,10.72,10.72,0,0,0-3.08-7.23L150,122.26l1.41,1.4L166.45,110a4.07,4.07,0,0,0,1.28-2.34A4.25,4.25,0,0,0,167.35,105ZM51.08,127.57A5.31,5.31,0,0,1,51.42,117h.36a5.32,5.32,0,0,1,4.95,5.65A5.33,5.33,0,0,1,51.08,127.57Zm9.05,12.15a5.31,5.31,0,0,1-3.92-8.9l3.17-3.34.52-.56a5.31,5.31,0,0,1,7.72,7.3l-1.48,1.56-1,1.06L65,137l-.66.7L64,138l0,.06A5.28,5.28,0,0,1,60.13,139.72ZM72,151a5.31,5.31,0,0,1-7.74-7.27l2.64-2.8h0l1-1.08,2.58-2.73.06-.07h0l.27-.29a5.31,5.31,0,0,1,7.72,7.3Zm11.68,6.79A5.31,5.31,0,0,1,84,147.13h.36a5.31,5.31,0,0,1-.7,10.6Zm64.93-36.85L107.25,79.68h0l.47-1.17a2.24,2.24,0,0,0-.15-2,2,2,0,0,0-1.56-1l-3.07-.18h0l-3.36-.2-.25,4A46.34,46.34,0,0,1,94,96.46c-4.72,8.39-11,11.28-12,11.47a18,18,0,0,1-3.11.4,3.67,3.67,0,0,1-1.55-.28l-1.57-.73a2.82,2.82,0,0,1-1.43-1.58,3.06,3.06,0,0,1,0-2.13l15.72-34.5,2.68-1.76,1.07-.41h0l19.76-7.54a11.11,11.11,0,0,1,3.86-.73h.11a11,11,0,0,1,3.77.66l23,8.27L164,106.94Z"
                                                    transform="translate(0 -36.65)"
                                                    style="fill: #f9ae15"
                                                />
                                                <path
                                                    d="M171,40.56,155.42,48.3A9.76,9.76,0,0,0,151,61.4l20.34,40.78a9.76,9.76,0,0,0,13.1,4.37L200,98.81Zm13.48,58.27a3.74,3.74,0,1,1,3.74-3.74A3.74,3.74,0,0,1,184.43,98.83Z"
                                                    transform="translate(0 -36.65)"
                                                    style="fill: #f9ae15"
                                                />
                                                <path
                                                    d="M0,89.91l14.2,10a9.76,9.76,0,0,0,13.6-2.38L54,60.23a9.76,9.76,0,0,0-2.37-13.61l-14.2-10ZM12.25,88a3.74,3.74,0,1,1,3.14,4.26A3.75,3.75,0,0,1,12.25,88Z"
                                                    transform="translate(0 -36.65)"
                                                    style="fill: #f9ae15"
                                                />
                                            </svg>
                                            <strong>{{ $t('searchform.netlinking') }}</strong>
                                        </div>
                                        <ul>
                                            <li>{{ $t('searchform.budget') }}</li>
                                            <li>{{ $t('searchform.orders') }}</li>
                                            <li>{{ $t('searchform.tracking') }}</li>
                                        </ul>
                                        <div class="item--cta">
                                            <a href="/campaigns" target="blank"
                                                ><strong>{{ $t('searchform.clickhere') }}</strong
                                                ><br />
                                                {{ $t('searchform.help') }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="padding-right: 5px; padding-left: 5px">
                                <div class="item--header" data-aos="fade-right" data-aos-delay="1000">
                                    <div class="item--header--top" style="min-height: 50px; padding-top: 15px">
                                        {{ $t('searchform.gestion') }}
                                        <strong>{{ $t('searchform.agency') }}</strong>
                                    </div>
                                    <div class="item--header--content" style="padding: 0.5rem">
                                        <div class="item--header--icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 714.7 640"
                                            >
                                                <path
                                                    d="M154.7,704h480A117,117,0,0,0,752,586.7v-384a31.47,31.47,0,0,0-32-32H554.7V160a95.64,95.64,0,0,0-96-96h-128a95.64,95.64,0,0,0-96,96v10.7H69.3a31.47,31.47,0,0,0-32,32v384A117,117,0,0,0,154.7,704Zm144-544a31.47,31.47,0,0,1,32-32h128a31.47,31.47,0,0,1,32,32v10.7h-192ZM101.3,234.7H688V320a52.85,52.85,0,0,1-35.2,50.1h0l-240,85.3h0c-11.7,4.3-24.5,4.3-35.2,0h0l-240-85.3h0A53,53,0,0,1,102.4,320V234.7Zm0,189.8c4.3,2.1,9.6,4.3,13.9,6.4h0l240,85.3h0a125.09,125.09,0,0,0,79,0h0l240-85.3h0c5.3-2.1,9.6-4.3,13.9-6.4V586.6a52.78,52.78,0,0,1-53.3,53.3h-480a52.78,52.78,0,0,1-53.3-53.3V424.5Z"
                                                    transform="translate(-37.3 -64)"
                                                    style="fill: #f9ae15"
                                                />
                                            </svg>
                                            <strong>{{ $t('searchform.agent') }} </strong>
                                        </div>
                                        <ul>
                                            <li>{{ $t('searchform.ui') }}</li>
                                            <li>{{ $t('searchform.assignbudget') }}</li>
                                            <li>{{ $t('searchform.partner') }}</li>
                                        </ul>
                                        <div class="item--cta">
                                            <a href="/campaigns" target="blank"
                                                ><strong>{{ $t('searchform.clickhere') }}</strong
                                                ><br />
                                                {{ $t('searchform.help') }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div v-if="null !== message" class="mt-3 bloc-nlform">
            {{ message }}
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        name: 'HomeSearch',
        title: 'searchform.searchby',
        data: function () {
            return {
                searchKeyword: '',
                searchPage: '',
                searchDomain: '',
                message: null
            }
        },
        methods: {
            ...mapMutations('search', {
                setFilters: 'setFilters'
            })
        }
    }
</script>
